<script>
import api from '@/command/api'
import Switch from '../../../components/FormView/FormSwitch'
import RadioButton from '../../../components/FormView/FormRadioButton'
import Upload from '../../../components/FormView/FormUpload'
import Select from '../../../components/FormView/FormSelect'
import Button from '../../../components/FormView/FormButton'
import DropDownInput from '../../../components/FormView/FormDropDownInput'
import { getAction } from '@/command/netTool'
import { Card, Col } from 'ant-design-vue'

export default {
  props: {
    shopId: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: [],
      listGoodsList: [],
      productTypeList: [],
      chi: [
        [],
        ['710*200'],
        ['244*200', '244*200'],
        ['244*416', '244*200', '244*200'],
        ['710*200', '244*416', '244*200', '244*200'],
      ],
    }
  },
  mounted() {
    this.getProductTypeList()

    this.getFill()
  },
  methods: {
    async getProductTypeList() {
      const result = await api.command.search.call(this, {
        url: `/api/base/product/getProductTypeList`,
      })
      this.productTypeList = result.map((el) => {
        return {
          ...el,
          name: el.moduleName,
          value: el.moduleCode,
        }
      })
      this.$forceUpdate()
    },
    async getListGoods(value = '', type = '') {
      const result = await api.command.search.call(this, {
        url: `/api/base/product/listGoodsByType?name=${value}&type=${type}&shopId=${this.shopId}`,
      })
      this.listGoods = result.map((el) => {
        return {
          ...el,
          name: el.name,
          value: el.id,
        }
      })
      this.$forceUpdate()
    },
    getFill() {
      if (this.data[`farmMainBodyAdvertising${this.data.styleType}`].length) return
      for (let i = 0; i < +this.data.styleType; i++) {
        this.data[`farmMainBodyAdvertising${this.data.styleType}`].push({})
      }
    },
    renderAdvertisingSpace() {
      return (
        <div>
          <Col span={24} class="flex">
            <span class="title">广告位</span>
            <Switch
              item={{}}
              value={this.data.advertisingSpace}
              onChange={(val) => {
                this.data.advertisingSpace = val
              }}
            />
          </Col>
          {this.data.advertisingSpace == '0' && (
            <Col span={24} class="flex">
              <span class="title-h2">选择样式</span>
              <RadioButton
                item={{
                  onChange: (val) => {
                    this.data.styleType = val
                    if (!this.data[`farmMainBodyAdvertising${this.data.styleType}`]) {
                      this.data[`farmMainBodyAdvertising${this.data.styleType}`] = []
                      this.getFill()
                    }
                  },
                }}
                value={this.data.styleType || '1'}
                typeData={[
                  {
                    name: '样式一',
                    value: '1',
                  },
                  {
                    name: '样式二',
                    value: '2',
                  },
                  {
                    name: '样式三',
                    value: '3',
                  },
                  {
                    name: '样式四',
                    value: '4',
                  },
                ]}
              />
            </Col>
          )}
          {this.data.advertisingSpace == '0' &&
            this.data[`farmMainBodyAdvertising${this.data.styleType}`].length &&
            this.data[`farmMainBodyAdvertising${this.data.styleType}`].map((item, i) => (
              <Col span={24} class="flex" style={{ alignItems: 'unset' }}>
                <span class="title-h2"></span>
                <div>
                  <span class="title" style="margin: 0">
                    广告位{i + 1}
                  </span>
                </div>
                <div>
                  <Upload
                    item={{}}
                    value={item.bannerUrl}
                    onChange={(val) => {
                      item.bannerUrl = val
                      this.$forceUpdate()
                      this.$emit("send")
                    }}
                  />
                  <div>尺寸{this.chi[+this.data.styleType][i]}，JPG、PNG</div>
                </div>
                <Col span={12}>
                  <div class="flex" style="marginTop: 10px">
                    <span class="title">商品类型</span>
                    <Select
                      item={{
                        name: '商品类型',
                        onChange: (data) => {
                          item.typeId = data
                          item.commodityId = ''
                          item.commodityName = ''
                          this.$forceUpdate()
                        },
                      }}
                      value={item.typeId}
                      typeData={this.productTypeList}
                    />
                  </div>
                  <div class="flex" style="marginTop: 10px">
                    <span class="title">商品选择</span>
                    <DropDownInput
                      item={{
                        name: '商品选择',
                        inputProps: {
                          addonBefore: <a-icon type="search" />,
                        },
                        onInputSearch: (val) => {
                          if (!item.typeId) {
                            this.$message.warning('请先选择商品类型~')
                            return false
                          }
                          item.commodityName = val
                          this.getListGoods(val, item.typeId)
                        },
                        onMenuClick: (data) => {
                          item.commodityId = data.id
                          item.commodityName = data.name
                          this.$forceUpdate()
                        },
                      }}
                      style="width: 100%;height: 33px"
                      value={item.commodityName || ''}
                      typeData={this.listGoods}
                    />
                  </div>
                </Col>
              </Col>
            ))}
        </div>
      )
    },
    renderHot() {
      return (
        <div>
          <Col span={24} class="flex">
            <span class="title">店铺热销</span>
            <Switch
              item={{}}
              value={this.data.shopHot}
              onChange={(val) => {
                this.data.shopHot = val
                if (!this.data.farmMainBodyHots.length) {
                  this.data.farmMainBodyHots = [{}]
                }
              }}
            />
            <div style="margin-left:10px">建议尺寸710*1046，JPG、PNG</div>
          </Col>
          {this.data.shopHot == 0 &&
            (this.data.farmMainBodyHots || []).map((item, i) => (
              <Col span={24} class="flex" style={{ alignItems: 'unset' }}>
                <span class="title-h2"></span>

                <div>
                  <Upload
                    item={{}}
                    value={item.bannerUrl}
                    onChange={(val) => {
                      item.bannerUrl = val
                      this.$forceUpdate()
                      this.$emit("send")
                    }}
                  />
                </div>
                <Col span={12}>
                  <div style="marginTop: 10px">
                    <span class="title" style="margin: 10px 0;">
                      商品选择
                    </span>
                    <DropDownInput
                      item={{
                        name: '商品选择',
                        inputProps: {
                          addonBefore: <a-icon type="search" />,
                        },
                        onInputSearch: (val) => {
                          item.commodityName = val
                          this.getListGoods(val, 'farm_native_product')
                        },
                        onMenuClick: (data) => {
                          item.commodityId = data.id
                          item.commodityName = data.name
                          this.$forceUpdate()
                        },
                      }}
                      style="width: 100%;height: 33px"
                      value={item.commodityName || ''}
                      typeData={this.listGoods}
                    />
                  </div>
                </Col>
                <Col span={3} style="marginTop:38px">
                  <Button
                    item={{
                      label: '删除',
                      props: {
                        type: 'danger',
                        ghost: true,
                      },
                      onClick: () => {
                        if (this.data.farmMainBodyHots.length === 1) {
                          this.$message.warning('至少保留一个热销~')
                        } else {
                          this.data.farmMainBodyHots.splice(i, 1)
                        }
                      },
                    }}
                  />
                </Col>
              </Col>
            ))}
          {this.data.shopHot == 0 && (
            <Col span={20} class="flex">
              <span class="title-h2"></span>
              <Button
                item={{
                  label: `+ 添加(${this.data.farmMainBodyHots.length}/6)`,
                  props: {
                    type: 'dashed',
                    ghost: true,
                  },
                  onClick: () => {
                    if (this.data.farmMainBodyHots.length < 6) {
                      this.data.farmMainBodyHots.push({})
                    } else {
                      this.$message.warning('已是最大添加数~')
                    }
                  },
                }}
              />
            </Col>
          )}
        </div>
      )
    },
  },
  render() {
    return (
      <div class="shopHome">
        {this.data.globalAdvertisingSpace === '0' && this.renderAdvertisingSpace()}
        {this.data.globalShopHot === '0' && this.renderHot()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.shopHome {
  .flex {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }
  .title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 15px;
    width: 70px;
    margin-right: 15px;
    display: block;
  }
  .title-h2 {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    width: 70px;
    margin-right: 15px;
    display: block;
    text-align: right;
  }
}
</style>
