<script>
import api from '@/command/api'
import TableVIew from '../../../components/TableVIew'
import FormButton from '../../../components/FormView/FormButton'
import Input from '../../../components/FormView/FormInput'
import Select from '../../../components/FormView/FormSelect'
import { getAction } from '@/command/netTool'
import { Card, Col } from 'ant-design-vue'

import { goodsTypeList } from '@/utils/textFile'
export default {
  props: {
    shopId: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: [],
      baseModuleList: [],
      farmGoodsCategoryList: [],
      farmTicketTypeList: [],
    }
  },
  mounted() {
    this.getBaseModuleList()
    this.getFarmGoodsCategoryList()
    this.getFarmTicketTypeList()
    this.list = this.data
  },
  methods: {
    async getBaseModuleList() {
      const result = await api.command.search.call(this, {
        url: `/api/base/product/getProductTypeList`,
      })
      this.baseModuleList = result.map((el) => {
        return {
          ...el,
          name: el.moduleName,
          value: el.moduleCode,
        }
      })
    },
    async getFarmGoodsCategoryList() {
      const result = await api.command.search.call(this, {
        url: `/api/config/farmGoodsCategory/list?shopId=${this.shopId}`,
      })
      this.farmGoodsCategoryList = result.map((el) => {
        return {
          name: el.name,
          value: el.id,
          custom: el.type === '1',
        }
      })
    },
    async getFarmTicketTypeList() {
      const result = await api.command.search.call(this, {
        url: `/api/farmTicketType/list?shopId=${this.shopId}`,
      })
      this.farmTicketTypeList = result.map((el) => {
        return {
          ...el,
          name: el.name,
          value: el.id,
        }
      })
    },
    getList(code) {
      if (code === 'farm_native_product') {
        return this.farmGoodsCategoryList
      } else if (code === 'farm_scenic_ticket') {
        return this.farmTicketTypeList
      } else {
        return []
      }
    },
    getItem() {
      return {
        type: 'table',
        showFootButton: false,
        havedrag: true,
        showPagination: false,
        showRowSelect: false,
        columns: [
          {
            dataIndex: 'a1',
            align: 'left',
            title: '拖动排序',
            width: 100,
            customRender: function () {
              return <a-icon type="menu" />
            },
          },
          {
            dataIndex: 'moduleCode',
            align: 'left',
            title: '模块',
            type: 'select',
            width: 230,
            customRender: (text, records, index) => {
              return (
                <Select
                  item={{
                    name: '模块',
                    onChange: (data) => {
                      this.list[index].moduleCode = data
                      this.list[index].moduleName = this.baseModuleList.filter((e) => e.value == data)[0].name
                      if (!['farm_native_product', 'farm_scenic_ticket'].includes(data)) {
                        this.list[index].subModuleName = this.list[index].moduleName
                      } else {
                        this.list[index].subModuleName = undefined
                      }
                      this.$forceUpdate()
                    },
                  }}
                  value={this.list[index].moduleCode}
                  typeData={this.baseModuleList}
                />
              )
            },
          },
          {
            dataIndex: 'subModuleName',
            align: 'left',
            title: '二级模块',
            type: 'select',
            width: 230,
            customRender: (text, records, index) => {
              return (
                <Select
                  item={{
                    name: '二级模块',
                    disabled: !['farm_native_product', 'farm_scenic_ticket'].includes(this.list[index].moduleCode),
                    onChange: (data) => {
                      this.list[index].subModuleId = data
                      this.list[index].subModuleName = this.getList(this.list[index].moduleCode).filter(
                        (e) => e.value == data
                      )[0].name

                      this.$forceUpdate()
                    },
                  }}
                  value={this.list[index].subModuleName || undefined}
                  typeData={this.getList(this.list[index].moduleCode)}
                />
              )
            },
          },
          {
            dataIndex: 'showName',
            align: 'left',
            title: '板块名称',
            type: 'input',
            customRender: (text, records, index) => {
              return (
                <Input
                  item={{
                    name: '板块名称',
                    maxLength: 4,
                    showCount: true,
                    onChange: (val) => {
                      this.list[index].showName = val
                      this.$emit('send')
                    },
                  }}
                  value={this.list[index].showName}
                />
              )
            },
          },
          {
            dataIndex: 'a10',
            align: 'center',
            width: 60,
            title: '操作',
            type: 'buttonGroup',
            typeData: ({ records, item, index }) => {
              return [
                {
                  name: '删除',
                  popTitle: '确认是否删除吗?',
                  onClick: () => {
                    this.list.splice(index, 1)
                  },
                },
              ]
            },
          },
        ],
        dataSource: this.list,
      }
    },
  },
  render() {
    return (
      <div style="width: 100%">
        <Col>
          <span style="color: rgba(0, 0, 0, 0.85);font-size: 14px;">商品列表</span>
        </Col>
        <Col>
          <TableVIew
            props={this.getItem()}
            columns={this.getItem().columns}
            styles={{ marginTop: '10px' }}
            size={'small'}
            showAddButton={true}
            havedrag={true}
            {...this.getItem()}
            class="table-view"
          />
          <FormButton
            item={{
              label: '+新增',
              props: {
                type: 'dashed',
                ghost: true,
                style: {
                  marginTop: '10px',
                },
              },
              onClick: () => {
                this.list.push({ showName: '' })
              },
            }}
          />
        </Col>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.table-view {
  /deep/.ant-table-body {
    max-height: calc(100vh - 435px);
  }
}
</style>
