<script>
import DetailFormGroup from '../../../components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import api from '@/command/api'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
import ProductList from './productList.vue'
import ShopHome from './shopHome.vue'
import { Input, InputNumber } from 'ant-design-vue'
import moment from 'moment'
import { getAction, postAction } from '@/command/netTool'
import { operatingCycle, goodsTypeList } from '@/utils/textFile'
import Naves from '@/views/com/Naves'
export default {
  name: 'entityInformationDetail',
  data() {
    return {
      activeKey: 0,
      activeRadio: 0,
      ...api.command.getState(),
      detail: {
        farmFestivalCelebrationVos: [],
        mainBodyName: '',
        linkPhone: '',
        address: '',
        radioButton: '',
        businessCycle: ['1', '2', '3', '4', '5', '6', '7'],
        storeOperation: '1',
        bannerUrl: null,
        salePrice: null,
        vipPrice: null,
        firmId: null,
        openTime: '00:00',
        closeTime: '23:59',
        moduleList: [],
        abc: [],
      },
      adsenseList1: [{}],
      adsenseList2: [{}],
      // 标签组
      labelGroup: [],
      // 主体信息类型
      mainBodyType: [],
      typeDataZQ: operatingCycle,
      isShowSnapshot: false,
      linkPhoneStr: null,
      shopApplyId: null,
      firmId: null,
    }
  },
  mounted() {
    const { id, shopApplyId, firmId, firmName } = this.$route.query
    this.shopApplyId = shopApplyId
    if (firmId) {
      getAction('/farmShopApply/firmInfo/' + firmId, {}, '/api').then((r) => {
        if (r.code == 200) {
          let arrData = r.data
          this.detail.firmId = arrData.firmId
          this.detail.firmName = arrData.firmName
          this.detail.address = arrData.addr
          this.detail.provinceName = arrData.province
          this.detail.provinceCode = arrData.provinceCode
          this.detail.cityName = arrData.city
          this.detail.cityCode = arrData.cityCode
          this.detail.areaName = arrData.area
          this.detail.areaCode = arrData.areaCode
          this.detail.latitude = arrData.lat
          this.detail.longitude = arrData.lon
          this.detail.linkPhone = arrData.sj
        } else {
          this.$message.warning('该主体不存在！')
          return false
        }
      })
    }

    // 获取标签列表数据
    let url = id ? `/api/base/farmLabelData/getBySelf?busId=${id}` : '/api/base/farmLabelData/getBySelf'
    api.command.getLabelGroup.call(this, {
      url: url,
    })
    // 获取详情
    if (id && id != 'null') {
      api.command.getDetail.call(this, { url: `/commodity/farmMainBody/${id}` }).then((result) => {
        let data = JSON.parse(JSON.stringify(result))
        this.linkPhoneStr = result.linkPhone
        this.detail.farmMainBodyFitmentDTO.styleType = this.detail.farmMainBodyFitmentDTO.styleType || '1'
        this.detail.farmMainBodyFitmentDTO[`farmMainBodyAdvertising${this.detail.farmMainBodyFitmentDTO.styleType}`] =
          data.farmMainBodyFitmentDTO.farmMainBodyAdvertisings || []
        // 初始化详情
        this.initDetail(data)
        this.getMainBody(result.firmId)
        this.onMainBodySearch(result.firmName)
        this.$forceUpdate()
        this.getModuleList(id)
      })
    } else {
      this.onMainBodySearch(firmName)
      this.getModuleList()
    }
  },
  methods: {
    getModuleList(shopId) {
      getAction('/commodity/farmMainBody/getModuleList?shopId=' + shopId, {}, '/api').then((res) => {
        let { data } = res
        this.detail.moduleList = data.map((e, i) => {
          return {
            ...e,
            id: e.id ?? i,
          }
        })
        this.$forceUpdate()
      })
    },
    onProductMenuClick(data) {
      if (!(data.lat && data.lon)) {
        this.$message.warning('缺少主体经纬度信息，请去未来乡村中台完善经纬度信息！')
        return false
      }
      const { firmId } = data
      this.getBaseDataInit(firmId)
      this.getMainBody(firmId)
      this.detail.linkPhone = ''
      let arrPhoto = this.linkPhoneStr ? `${this.linkPhoneStr},${data.sj}` : `${data.sj}`
      let arr = [...new Set(arrPhoto.split(','))].join(',')
      this.detail.linkPhone = arr
    },
    getMainBody(id) {
      getAction(`/api/baseMonitor/queryBaseMonitorListByFirmId?firmId=${id}`).then((e) => {
        this.isShowSnapshot = e.data.length > 0 ? true : false
      })
    },
    initDetail(result) {
      // eslint-disable-next-line prettier/prettier
      this.detail['farmFestivalCelebrationVos'] = result.farmFestivalCelebrationVos
        ? result.farmFestivalCelebrationVos
        : []
      // eslint-disable-next-line prettier/prettier
      this.detail['businessCycle'] = result.businessCycle ? result.businessCycle.split('、')[0].split(',') : []
      this.$forceUpdate()
    },
    async onMainBodySearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/firm/list?search=${value ?? ''}`,
      })
      this.mainBodyType = searchResult.map((el) => {
        return {
          ...el,
          name: el.firmName,
          value: el.firmId,
        }
      })
    },
    getAddressInit(data) {
      getAction(`/api/gao?location=${data.lng},${data.lat}`).then((e) => {
        if (e.code == 200) {
          this.detail.areaCode = e.data.codes
          this.detail.latitude = data.lat
          this.detail.longitude = data.lng
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    onOpenMap() {
      apiTool.openMap({
        title: '打开地图',
        onSuccess: (data) => {
          this.getAddressInit(data)
        },
      })
    },

    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '店铺名称',
            type: 'input',
            cols: 12,
            key: 'mainBodyName',
            props: {
              maxLength: 32,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '关联主体',
            type: 'dropDownInput',
            cols: 12,
            key: 'firmName',
            onInputSearch: this.onMainBodySearch,
            onMenuClick: this.onProductMenuClick,
            typeData: this.mainBodyType,
            valueKey: 'firmId',
            inputProps: {
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
                message: '请选择关联主体',
              },
            ],
          },
          {
            name: '联系方式',
            type: 'selectMode',
            cols: 7,
            colsType: {
              xs: 12,
              xxl: 7,
              xl: 12,
            },
            props: {
              mode: 'tags',
              open: false,
              placeholder: '多联系方式请在输入一个后鼠标点击页面空白处。',
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
            key: 'linkPhone',
          },
          {
            name: '店铺运营',
            type: 'row',
            cols: 5,
            children: [
              {
                type: 'radioButton',
                key: 'storeOperation',
                cols: 24,
                typeData: [
                  {
                    name: '联系商家',
                    value: '1',
                  },
                  {
                    name: '联系平台',
                    value: '2',
                  },
                ],
              },
            ],
          },
          {
            type: 'row',
            cols: 12,
            gutter: 20,
            children: [
              {
                name: '营业开始时间',
                type: 'timePicker',
                cols: 6,
                key: 'openTime',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'HH:mm',
                  allowClear: false,
                },
              },
              {
                name: '营业结束时间',
                type: 'timePicker',
                cols: 6,
                key: 'closeTime',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'HH:mm',
                  allowClear: false,
                },
              },
              {
                name: '营业周期',
                type: 'select',
                cols: 12,
                props: {
                  mode: 'multiple',
                },
                sort: true,
                key: 'businessCycle',
                typeData: this.typeDataZQ,
              },
            ],
          },
          {
            name: 'Banner选择',
            type: 'radioButton',
            key: 'bannerType',
            cols: 12,
            defaultValue: '0',
            typeData: [
              {
                name: 'banner图片',
                value: '0',
                display: true,
              },
              {
                name: '视频地址',
                value: '2',
                display: true,
              },
              {
                name: '数字基地监控视频',
                value: '1',
                display: this.isShowSnapshot,
              },
            ].filter((e) => e.display),
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '置顶（默认否）',
            type: 'radioButton',
            key: 'topFlag',
            cols: 12,
            defaultValue: '0',
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      }
    },
    getFormBox() {
      return {
        type: 'cardFormBox',
        children: [this.getForm2(), this.getForm23()],
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            <span style="color:#f00">*</span>
            Banner（尺寸750*420PX）限制1张
          </div>
        ),
        data: [
          {
            type: 'iconUpload',
            cols: 12,
            typeData: [
              {
                maxLength: 1,
                key: 'bannerUrl',
                desc: '<br /> 支持格式.png/.jpg',
              },
            ],
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string',
              },
            ],
          },
        ],
      }
    },
    getForm23() {
      return {
        type: 'cardForm',
        title: '宣传视频',
        data: [
          {
            type: 'iconUploadVideo',
            cols: 12,
            typeData: [
              {
                maxLength: 1,
                key: 'videoUrl',
                desc: '视频上传尺寸<20M <br /> 支持格式.mov/.mp4/.wmv/.avi',
              },
            ],
          },
        ],
      }
    },
    getForm2Copy() {
      return {
        type: 'cardForm',
        title: '数字基地监控视频',
        data: [this.isShowSnapshot ? this.getBaseDataInit(this.detail.firmId) : {}],
      }
    },
    getBaseDataInit(shopId = null) {
      return {
        type: 'baseUpload',
        key: 'monitorCode',
        maxLength: 1,
        cols: 24,
        shopId: shopId,
        setValue: this.detail.monitorCode,
        setShowValue: this.detail.showMonitors,
        setFirm: true,
      }
    },
    getForm3() {
      return {
        type: 'table',
        showFootButton: true,
        showRowSelect: false,
        showPagination: false,
        // table新增按钮回调
        onAddData: () => {
          return {
            festivalName: null,
            festivalDate: null,
          }
        },
        columns: [
          {
            dataIndex: 'festivalName',
            align: 'left',
            title: <div style={{ paddingLeft: '24px' }}>节庆名称</div>,
            width: '48%',
            customRender: function (text, records) {
              const onInput = (data) => {
                records.festivalName = data.target.value
              }
              return records.isEdit ? (
                <Input onInput={onInput} value={text} />
              ) : (
                <div style={{ paddingLeft: '24px' }}>{text}</div>
              )
            },
          },
          {
            dataIndex: 'festivalDate',
            align: 'left',
            title: '节庆日期',
            width: '48%',
            customRender: function (text, records) {
              const onChange = (data) => {
                records.festivalDate = moment(data).format('MM/DD')
              }
              return records.isEdit ? (
                <a-date-picker style={{ width: '100%' }} defaultValue={text} onChange={onChange}>
                  <template slot="renderExtraFooter">节庆日期</template>
                </a-date-picker>
              ) : (
                <div>{text}</div>
              )
            },
          },
          {
            dataIndex: 'a3',
            align: 'center',
            width: 200,
            title: '操作',
            tableType: 'editDelete',
            onSave: (text, records) => {
              if (records.festivalName && records.festivalDate) {
                records.isEdit = false
              } else {
                this.$message.error('请填入节庆名称/节庆日期')
              }
            },
          },
        ],
        dataSource: this.detail.farmFestivalCelebrationVos,
      }
    },
    getLabelForm(index) {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName',
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          typeData: [
            {
              name: '店铺私有',
              value: '2',
            },
            {
              name: '公用标签库',
              value: '1',
            },
          ],
        },
        index == 3 && {
          name: '标签分类',
          type: 'iconClass',
          key: 'iconUrl',
        },
      ].filter((e) => e)
    },
    getForm4() {
      return {
        type: 'row',
        children: (this.labelGroup || []).map((e, i) => {
          return (
            <CardIconLabelLocal
              data={this.getLabelForm(i)}
              title={e.labelTypeName}
              pubBool={false}
              style={{ marginBottom: '10px', minHeight: '200px' }}
              icons={e.list}
              cols={i == 2 ? 24 : 12}
            />
          )
        }),
      }
    },
    getForm7() {
      return {
        type: 'cardForm',
        cols: 24,
        showCard: false,
        data: [
          {
            type: 'rich',
            key: 'content',
            cols: 24,
          },
        ],
      }
    },
    getForm41() {
      return {
        title: '基本设置',
        type: 'cardFormRow',
        cols: 17,
        children: [
          <ShopHome
            data={this.detail.farmMainBodyFitmentDTO}
            shopId={this.detail.id}
            onSend={() => this.$forceUpdate()}
          />,
        ],
      }
    },
    getForm42() {
      return {
        type: 'cardFormRow',
        title: '基本设置',
        cols: 17,
        children: [
          <ProductList data={this.detail.moduleList} shopId={this.detail.id} onSend={() => this.$forceUpdate()} />,
        ],
      }
    },
    getAppModel(data) {
      return {
        type: 'cardForm',
        cols: 7,
        cardStyle: {
          minWidth: '420px',
        },
        data: [
          {
            type: 'iframe',
            url: `${apiTool.getSzncH5Url()}/shop?id=${this.detail.id}${
              this.activeRadio == 1 ? '&tabActive=1' : ''
            }&imitate=true`,
            styles: {},
            key: this.activeRadio + '',
            data,
          },
        ],
      }
    },
    shopDetails() {
      return {
        type: 'cardForm',
        cols: 24,
        data: [
          {
            type: 'rich',
            key: 'content',
            simulator: true,
            onWxClick: () => {
              apiTool.showModal({
                width: '800px',
                title: '微信图文采集',
                success: ({ data, setHidden }) => {
                  console.log(data, 2222222)
                  // const { url } = data
                  //  postAction('/common/urlParse', { url }).then(res => {
                  //   const { data } = res
                  //   this.detail.content = data
                  //   this.$forceUpdate()
                  //   setHidden()
                  // })
                },
                formData: {},
                form: [
                  {
                    type: 'textCard',
                    text: () => {
                      return (
                        <div>
                          <p>
                            1.下方表单输入链接，可以采集微信内容链接。例如：https://mp.weixin.qq.com/s/Gm3npsJ880dngKPj1DWDjg
                          </p>
                          <p>
                            2.可以使用微信内容，135内容复制到编辑器中即可。由于图片采集需要时间，所以等待20秒再提交表单。
                          </p>
                          <p>温馨提示：视频，过于复杂的样式，背景图，可能会导致采集失败。</p>
                        </div>
                      )
                    },
                    labelCol: { span: 0 },
                    wrapperCol: { span: 24 },
                  },
                  {
                    type: 'input',
                    key: 'url',
                    placeholder: '采集微信图文内容（填写微信图文链接）',
                    labelCol: { span: 0 },
                    wrapperCol: { span: 24 },
                    maxLength: 400,
                  },
                ],
                // foot: ({ close }) => {
                //   return (
                //     <Button onClick={close} type="primary">
                //       关闭
                //     </Button>
                //   )
                // },
              })
            },
          },
        ],
      }
    },
    pageDecorated() {
      return {
        type: 'table',
        showFootButton: false,
        showRowSelect: false,
        showPagination: false,
        columns: [
          {
            dataIndex: 'moduleName',
            align: 'left',
            width: '20%',
            title: <div style={{ paddingLeft: '24px' }}>模块</div>,
            customRender: function (text, records) {
              return <div style={{ paddingLeft: '24px' }}>{text}</div>
            },
          },
          {
            dataIndex: 'showName',
            align: 'left',
            width: '40%',
            title: '自定义名称',
            customRender: function (text, records) {
              const onInput = (data) => {
                records.showName = data.target.value
              }
              return <Input style="width: 70%" onInput={onInput} value={text} />
            },
          },
          {
            dataIndex: 'sort',
            align: 'left',
            width: '30%',
            title: '排序',
            customRender: function (text, records) {
              const onInput = (data) => {
                records.sort = data
              }
              return <InputNumber onChange={onInput} value={text} />
            },
          },
          {
            dataIndex: 'status',
            align: 'left',
            width: '10%',
            title: '显示',
            customRender: function (text, records) {
              const onInput = (data) => {
                records.status = data ? '0' : '1'
              }
              return <a-switch onChange={onInput} checked={text == '0' ? true : false} />
            },
          },
        ],
        dataSource: this.detail.moduleList,
      }
    },
    getFilterData() {
      return this.typeDataZQ.filter((e) => this.detail.businessCycle.indexOf(e.value) !== -1)
    },
    getString(str) {
      // eslint-disable-next-line prettier/prettier
      return str
        ? str
            .split(',')
            .filter((e) => e)
            .join(',')
        : ''
    },
    assignmentProcess() {},
    getLinkFirmID(firmId) {
      let arr = this.mainBodyType.filter((e) => e.firmId == firmId)
      let obj = {}
      if (arr.length > 0) {
        let arrData = arr[0]
        obj = {
          address: arrData.addr,
          provinceName: arrData.province,
          provinceCode: arrData.provinceCode,
          cityName: arrData.city,
          cityCode: arrData.cityCode,
          areaName: arrData.area,
          areaCode: arrData.areaCode,
          latitude: arrData.lat,
          longitude: arrData.lon,
        }
        return obj
      } else {
        this.$message.warning('该主体不存在！')
        return false
      }
    },
    getFoot() {
      let that = this
      const left = [
        {
          name: '上一步',
          display: this.activeKey != 0,
          onClick: (data) => (this.activeKey = this.activeKey - 1),
        },
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          display: true,
          onClick: (data) => {
            // if (!(nData.latitude && nData.longitude)) {
            //   this.$message.warning('缺少主体经纬度信息，请去未来乡村中台完善经纬度信息！')
            //   return false
            // }
            let nData = Object.assign({}, data, this.detail)

            let objData = this.getLinkFirmID(nData.firmId)
            if (!objData) return

            let lxfs = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/
            let phoneArr = nData.linkPhone.split(',')
            if (phoneArr.length > 0) {
              try {
                phoneArr.forEach((e, i) => {
                  if (!lxfs.test(e)) {
                    throw new Error('联系方式错误！')
                  }
                })
              } catch (error) {
                this.$message.warning('请正确输入联系方式！')
                console.log(error)
                return false
              }
            } else {
              this.$message.warning('请输入联系方式！')
              return false
            }

            if (this.$route.query.id) {
              try {
                nData.moduleList.forEach((e, i) => {
                  if (!e.moduleCode || !e.moduleName || !e.showName) {
                    throw new Error('请完善页面装修商品列表！')
                  }
                })
              } catch (error) {
                this.$message.warning('请完善页面装修商品列表！')
                return false
              }
            }

            // 获取标签
            let arr = []
            that.labelGroup.forEach((item) =>
              item.list.forEach((e) => {
                e['labelTypeCode'] = item.labelTypeCode
                e['labelTypeId'] = item.id
                e['labelTypeName'] = item.labelTypeName
              })
            )
            that.labelGroup.map((e) => arr.push(...e.list))
            // 获取筛选数据
            let filterData = this.getFilterData()
            this.detail.farmFestivalCelebrationVos.forEach((e) => {
              delete e.id
            })

            let monitorCode = null
            let monitorImg = null
            let showMonitors = []
            if (
              nData.monitorCode instanceof Array &&
              //   nData.bannerType == 1 &&
              nData.monitorCode.length > 0
            ) {
              monitorCode = (
                nData.monitorCode.find((e) => e.select) || {
                  monitorCode: '',
                }
              ).monitorCode
              monitorImg = (
                nData.monitorCode.find((e) => e.select) || {
                  monitorImg: '',
                }
              ).monitorImg
              nData.monitorCode.forEach((e) => {
                if (e.isOpen) {
                  showMonitors.push(e.monitorCode)
                }
              })
            }

            const farmMain = nData.farmMainBodyFitmentDTO
            if (this.$route.query.id && farmMain[`farmMainBodyAdvertising${farmMain.styleType}`]) {
              nData.farmMainBodyFitmentDTO.farmMainBodyAdvertisings =
                farmMain[`farmMainBodyAdvertising${farmMain.styleType}`]

              if (farmMain.advertisingSpace === '1') {
                nData.farmMainBodyFitmentDTO.farmMainBodyAdvertisings = []
              }
              if (farmMain.shopHot === '1') {
                nData.farmMainBodyFitmentDTO.farmMainBodyHots = []
              }
            }

            let obj = {
              // eslint-disable-next-line prettier/prettier
              farmFestivalCelebrationDTOS: nData.farmFestivalCelebrationVos,
              ...nData,
              farmLabelDataDTOS: arr.map((e) => ({
                ...e,
                czState: e.czState ? 1 : 0,
              })),
              businessCycle: filterData.map((e) => e.value).toString(),
              businessCycleName: filterData.map((e) => e.name).toString(),
              ...objData,
              //   farmhouseLabel: this.getString(this.detail.farmhouseLabel),
              //   foodDriveLabel: this.getString(this.detail.foodDriveLabel),
              //   otherLabel: this.getString(this.detail.otherLabel),
              //   scenicLabel: this.getString(this.detail.scenicLabel),
              monitorCode: monitorCode,
              monitorImg: monitorImg,
              showMonitors: showMonitors.toString(),
              shopApplyId: this.shopApplyId,
            }

            api.command[nData.id ? 'edit' : 'create']
              .call(this, {
                url: '/commodity/farmMainBody',
                params: {
                  ...obj,
                },
                isPost: false,
              })
              .then((result) => {
                this.$router.push('/commodityManagement/entityInformation')
              })
          },
        },
        {
          name: '下一步',
          display: this.activeKey != 3,
          onClick: (data) => {
            let nData = Object.assign({}, data, this.detail)
            if (this.activeKey == 0) {
              if (!(nData.mainBodyName && nData.firmId && nData.linkPhone && nData.bannerUrl)) {
                this.$message.warning('请完成表单填写')
                return
              }
            }
            this.activeKey = this.activeKey + 1
          },
        },
      ].filter((e) => e.display)
      const right = [
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/commodityManagement/entityInformation'),
          display: true,
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/commodity/farmMainBody/${this.detail.id}`,
              isPost: false,
            })
            setTimeout(() => {
              this.$router.push('/commodityManagement/entityInformation')
            }, 500)
          },
          display: this.detail.id,
        },
      ].filter((e) => e.display)
      return {
        left,
        right,
      }
    },
    renderTab() {
      if (this.activeKey === 0) {
        return [this.getForm1(), this.getFormBox(), this.isShowSnapshot && this.getForm2Copy()]
      } else if (this.activeKey === 1) {
        return [this.shopDetails()]
      } else if (this.activeKey === 2) {
        return [this.getForm3(), this.getForm4()]
      } else if (this.activeKey === 3) {
        // return [this.pageDecorated()]
        if (this.activeRadio === 0) {
          return [
            {
              type: 'cardFormBox',
              children: [this.getAppModel({ ...this.detail.farmMainBodyFitmentDTO }), this.getForm41()],
            },
          ]
        } else if (this.activeRadio === 1) {
          return [
            {
              type: 'cardFormBox',
              children: [this.getAppModel({ moduleList: this.detail.moduleList }), this.getForm42()],
            },
          ]
        }
      }
    },
  },
  render() {
    return (
      <div>
        <Naves
          navData={
            this.$route.query.id
              ? ['基础设置', '店铺详情', '高级设置', '页面装修']
              : ['基础设置', '店铺详情', '高级设置']
          }
          onActive={(index) => (this.activeKey = index)}
          activeCurrentKey={this.activeKey}
        />
        {this.activeKey === 3 && (
          <a-radio-group v-model={this.activeRadio} buttonStyle="solid">
            <a-radio-button value={0}>首页</a-radio-button>
            <a-radio-button value={1}>全部商品</a-radio-button>
          </a-radio-group>
        )}
        <DetailFormGroup foot={this.getFoot()} form={this.detail} data={this.renderTab()} />
        <br />
        <br />
        <br />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/ .detail-form-group {
  height: calc(100vh - 230px);
}
/deep/ .ant-radio-button-wrapper {
  height: 38px;
  padding: 0 20px;
  line-height: 36px;
}
</style>
